import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../../layout/layout'

import ContactBoost from '../../components/contact-boost'

const Page = () => (
        <Layout>

            <Helmet>
            <title>Zupr - Bedankt!</title>
            <meta name="description" content="Met Zupr laat u consumenten online zien waar producten in lokale, fysieke winkels verkrijgbaar zijn." />
            </Helmet>

            <section className="contact-boost divider">
                <div className="inner">
                    <div className="text">
                        <h2>Bedankt!</h2>
                        <p>Uw aanmelding wordt in behandeling genomen.</p>
                    </div>
                </div>
            </section>

            <ContactBoost />

    </Layout>
)

export default Page
